import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import styles from './Banner.module.scss'

const Stripcash = () => {
  const { allFile: { nodes: banners300x250 } } = useStaticQuery(graphql`
    query Banners300x250 {
      allFile(
        filter: {
          relativeDirectory: {eq: "ad-banners/Stripcash/300x250"}
        }
      ) {
        nodes {
          publicURL
        }
      }
  
    }
  `)

  const randomBanner = banners300x250[Math.floor(Math.random() * banners300x250.length)]

  return (
    <div className={styles.banner} style={{minHeight: '250px'}}>
      <a aria-label='Stripchat' href='https://go.clbjmp.com/api/goToTheRoom?userId=b425e97ed74bf6903bbf8b0854f317d1a838ea698b49d71b365c6f486c976c85' target='_blank' rel='noopener'>
        <img loading='lazy' src={randomBanner.publicURL} alt='Stripchat Ad' ></img>
      </a>
    </div>
  )

}

const Livejasmin = () => {

  useEffect(() => {
    const scriptTag = document.createElement('script')
    scriptTag.async = false
    scriptTag.defer = true
    scriptTag.src = `//pt.potawe.com/embed/discount?dimension=300x250&c=491571&categoryName=girl&psid=DeeperStudio&pstool=429&siteId=jasmin&cobrandId=&psprogram=revs&campaign_id=&ms_notrack=1&subAffId={SUBAFFID}`
    const body = document.getElementsByTagName('body')[0]
    body.appendChild(scriptTag)

    return () => {
      body.removeChild(scriptTag)
    }
  }, [])

  return (
    <div className={styles.banner}>
      <div
        data-awe-container-id="491571"
        style={{display: 'inline-block', width: '300px', height: '250px'}}>
      </div>
      <div
        className={styles.overlay}
        onClick={() => {
          window.open('//awptjmp.com/?siteId=jasmin&categoryName=girl&pageName=signup&performerName=&prm[psid]=DeeperStudio&prm[pstool]=205_1&prm[psprogram]=revs&prm[campaign_id]=&subAffId={SUBAFFID}&filters=','_blank','noopener')
        }}
      ></div>
    </div>
  )

}

export default {
  Stripcash,
  Livejasmin
}