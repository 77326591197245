import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import StarRatings from 'react-star-ratings'
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

import styles from './Slider.module.scss'

const query = graphql`
  query HomePageSliderQuery {

    models: allSloppyDeepModel(
      filter: {modelId: {in: [69, 66, 63, 1, 54, 38]}}
      ) {
      nodes {
        slug
        rating
        modelId
        primaryAka {
          nickname
        }
        profilePicture {
          childImageSharp {
            fluid(maxHeight: 250, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }

  }
`
 
const Slider = () => {
  const { models } = useStaticQuery(query)
  const covers = {
    '69': { // modelId
      src: 'https://gallery2.dditscdn.com/public/13/77dea2e8e08f6807932527c21ce05003.jpg',
      type: 'image/jpeg',
      alt: 'Hot cam girl with pink hair wearing provocant lingerie'
    },
    '66': { // modelId
      src: 'https://gallery1.dditscdn.com/public/12/27713262e9c221ee1ab0e612aff2ceef.jpg',
      type: 'image/jpeg',
      alt: 'Cute camgirl getting ready for her live cam show in stockings'
    },
    '63': { // modelId
      src: 'https://gallery3.dditscdn.com/public/14/5865ba2eb5d385c03c568b6ee61090f2.jpg',
      type: 'image/jpeg',
      alt: 'Athletic camgirl about to start a BDSM session in a dark room with red lights'
    },
    '1': { // modelId
      src: 'https://gallery2.dditscdn.com/public/1d/c87dccf4e53a56c058c7b8e78bd807a2.jpg',
      type: 'image/jpeg',
      alt: 'Sensual brunette cam girl posing in bed wearing sexy lingerie'
    },
    '54': { // modelId
      src: 'https://gallery0.dditscdn.com/public/17/52a0c0d717431ef17d0b445a4e177d47.jpg',
      type: 'image/jpeg',
      alt: 'Sensual blonde cam girl sitting on a table with her legs wide open'
    },
    '38': { // modelId
      src: 'https://gallery1.dditscdn.com/public/19/d9456b4a6a0ecd9906dc68a6ee7467d3.jpg',
      type: 'image/jpeg',
      alt: 'Latina camgirl with sexy black lingerie posing for her photoshoot'
    },
  }

  return (
    <>
      <Helmet>
        {/*Object
          .keys(covers)
          .map((nickname, idx) => <link key={idx} rel='preload' href={covers[nickname].src} as='image' type={covers[nickname].type} />)
        */}
      </Helmet>
      <AwesomeSlider fillParent>
        {models.nodes.map((model, idx) => 
          <div key={idx} data-src={covers[model.modelId].src} data-alt={covers[model.modelId].alt} className={styles.slide}>
            <Model data={model} />
          </div>
        )}
      </AwesomeSlider>
    </>
  )
}

const Model = ({ data }) => {
  return (
    <div className={styles.model}>
      <Link to={`/models/${data.slug}/`} className={styles.profilePicture} aria-label={data.primaryAka.nickname}>
        <Img loading='eager' fluid={{...data.profilePicture.childImageSharp.fluid, aspectRatio: 1 }} className={styles.profilePictureImg} />
      </Link>
      <div className={styles.modelMeta}>
        <Link to={`/models/${data.slug}/`} className={styles.modelNickname}>{data.primaryAka.nickname}</Link>
        <div className={styles.modelRating}>
          <StarRatings
            rating={data.rating}
            starEmptyColor="#535353"
            starRatedColor="#EBEBEB"
            starDimension="3vmin"
            starSpacing=".01vmin"
          />
        </div>
        
      </div>
    </div>
  )
}

export default Slider