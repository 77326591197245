import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import SEO from 'components/SEO'
import Slider from './Slider'
import VideoCard from 'components/VideoCard'
import PartnersLinks from 'components/PartnersLinks'
import Banner from 'components/Banner'
import styles from './Home.module.scss'

const HomePage = ({ data }) => {
  const { videos, banner, logo } = data

  const organizationSchema = {
    "@context" : "http://schema.org",
    "@type" : "Organization",
    "name" : "SloppyDeep",
    "url" : "https://sloppydeep.com/",
    "logo": `https://sloppydeep.com/${logo.publicURL}`,
    "sameAs" : [
      "https://twitter.com/SloppyDeepCom",
      "https://reddit.com/r/CamGirlsIndex",
      "https://t.me/SloppyDeep",
      "https://discord.gg/ws9wqMJ"
    ] 
  }

  const searchSchema = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    "url": "https://sloppydeep.com/",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://sloppydeep.com/videos/search?query={search_term_string}",
      "query-input": "required name=search_term_string"
    }
  }

  const sloppyPartners = [
    {name: 'live xxx sex cams', url: 'https://www.livexxxsexcams.com/'},
    {name: 'Handjob', url: 'https://www.plusporn.net/handjob/'},
    {name: 'amateur sex video', url: 'http://www.babesdg.com/'},
    {name: 'NicePussy.Org', url: 'https://nicepussy.org/'},
    {name: 'Webcam Porn', url: 'https://proncoupon.com/best-webcam-porn-sites/'},
    {name: 'Escort Porn', url: 'https://bestescortporn.com/'},
    {name: 'Planet Sluts', url: 'https://planetsluts.com/'},
    {name: 'Sci Fi Sex', url: 'http://galacticgirls.com/'},
    {name: 'PronCoupon', url: 'https://proncoupon.com/'},
    {name: 'Porn Passwords', url: 'https://pronlogin.com/'},
    {name: 'BrazzX', url: 'https://brazzx.com/'},
    {name: 'Sexy Webcams', url: 'https://seksdump.com/sexy-webcams/'},
    {name: 'PervyStudio.com', url: 'http://pervystudio.com/'},
    {name: 'Cam Dude', url: 'https://camdude.com'},
    {name: 'Watch JAV Online', url: 'https://javgg.eu/'},
    {name: 'Deep throat sexfilms', url: 'https://sexfilms.biz/hot/deep-throat/'},
    {name: 'www.fetishwebcamblog.com', url: 'http://www.fetishwebcamblog.com/'},
    {name: 'seemewank', url: 'http://seemewank.com/'},
    {name: 'Squirting girls', url: 'https://squirtingcamgirls.com/'},
    {name: 'Anal Sex', url: 'https://analsex.com/'},
    {name: 'XXX Video Editor', url: 'https://www.xxxvideoeditor.com/'},
    {name: 'Cheap Sexcams', url: 'https://cheaplivesexcams.com/'},
    {name: '3x art porn videos', url: 'http://3xartporn.com/'},
    {name: 'Vacation with Tropical Latinas', url: 'http://reddiamondgirls.com/About-Us.php'},
    {name: 'Adult Entertainment Prague', url: 'http://www.temptingbeauties.com/'},
    {name: 'milfs live', url: 'https://milfnakedcam.com/'},
    {name: 'Anal Sex Dolls', url: 'http://www.analsexdolls.com/'},
    {name: 'Anal Porn Sex', url: 'http://www.analpornsex.org/'},
    {name: 'Naked Live Sex', url: 'http://www.nakedlivesex.com/'},
    {name: 'LIVE PORN CAMS', url: 'https://liveporncams.live/'},
    {name: 'Top UK Porn', url: 'https://www.topukporn.com/'},
    {name: 'Masturbate Together', url: 'https://www.masturbate2gether.com/'},
    {name: 'SEXY STRIP CAMS', url: 'https://www.sexy-strip.com/'},
    {name: 'Caribbean Resort Girls', url: 'https://resortgirls.escortbook.com/links'},
    {name: 'Meet Red Heads', url: 'http://www.redheadescorts.com/'},
  ]
  
  return (
    <>
      <SEO
        schemas={[organizationSchema, searchSchema]}
        title={`SloppyDeep | Best recorded cam shows tube`}
        description={`Looking to find the best cam girl of your life? SloppyDeep got you covered! We selected the hottest camgirls all over the web. Surf between thousands of cam models and watch free cam shows everyday! Not enough? Then explore the video section and jerk off with our top quality HD cam recordings!`}
        keywords={`recorded webcam shows, best cam sites, hottest cam girls, best cam models, best cam girl tube`}
        imagePath={banner.publicURL}
        imageAlt={'redhead teen cam girl wearing pink lingerie and posing sexy'}
        pagePath={`/`}
        robots='index, follow'
      />
      <Helmet>
        { /* prefetch for thumbnails */}
        <link rel='preconnect dns-prefetch' href='https://galleryn0.awemwh.com' crossorigin />
      </Helmet>
      <div className={styles.sliderContainer}>
        <Slider />
      </div>
      <main className={`${styles.homeScreen} screen-container`}>
        <h1 className={styles.titleBlock}>Latest Videos</h1>
        <div className={`${styles.videosBlock} video-cards-container`}>
          <VideoList videos={videos.nodes} />
        </div>

        {sloppyPartners.length > 0 &&
          <>
            <div className={styles.titleBlock}>Sloppy Partners</div>
            <div className={styles.partnersLinksBlock}>
              <PartnersLinks links={sloppyPartners} />
            </div>
          </>
        }

      </main>
    </>
  )
}

const VideoList = ({ videos }) => {

  const firstBannerIndex = 3 + Math.floor(Math.random() * 4) // [3-6]
  const bannerFrequency = 10

  let componentList = []

  for (let i = 0; i < videos.length; i++) {
    const shouldPushBanner = (firstBannerIndex + i) % bannerFrequency === 0

    if (shouldPushBanner) {
      componentList.push(i < 10
        ? <Banner.Livejasmin key={`banner-${i}`} />
        : <Banner.Stripcash key={`banner-${i}`} />)
    }

    componentList.push(<VideoCard key={videos[i].slug} data={videos[i]} />)
  }

  return componentList
}

export const query = graphql`
  query HomePageQuery {

    logo: file(
      relativeDirectory: {eq: ""},
      name: {eq: "logoV1"}
    ) {
      publicURL
    }

    banner: file(
      relativeDirectory: {regex: "/seo-banner/"},
      name: {eq: "home"}
    ) {
      publicURL
    }

    videos: allSloppyDeepVideo(sort: {fields: publishDate, order: DESC}, limit: 27) {
      nodes {
        slug
        title
        views
        publishDate
        duration
        isHd
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 380, maxHeight: 214, quality: 80, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        thumbnailAlt
        preview
        model {
          slug
          primaryAka {
            nickname
          }
          profilePicture {
            childImageSharp {
              fluid(maxWidth: 50, maxHeight: 50, quality: 80, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  
  }
`

export default HomePage
